import React from 'react'
import './Modal.scss'
import AddUser from 'features/Admin/components/AddUser'
import AddExternalUser from 'features/Admin/components/AddExternalUser'
import ChainPreview from 'features/Chain/components/chainBuilder/ChainPreview'
import TubeWidth from 'features/Chain/components/TubeWidth'
import ProjectDetails from 'features/Project/components/ProjectDetails'
import ProjectStatusHistory from 'features/Project/components/ProjectStatusHistory'
import ChangePassword from 'features/User/components/ChangePassword'
import UpdateSignature from 'features/User/components/UpdateSignature'
import VersionSelect from 'features/Version/components/VersionSelect'
import ProjectSettingsModal from 'features/Project/components/ProjectSettingsModal'
import { typedUseSelector } from 'utils/helpers'
import EstimatorModal from 'features/Project/components/EstimatorModal'

export type ModalKey = typeof modalKeys[number]
const modalKeys = [
  'addUser',
  'addExternalUser',
  'changePassword',
  'chainPreview',
  'projectDetails',
  'statusHistory',
  'versionSelect',
  'tubeWidth',
  'projectSettings',
  'noEstimator',
  'updateSignature'
] as const

function Modal() {
  const content = typedUseSelector((state) => state.ScreenReducer.content)

  function renderContent() {
    switch (content) {
      case 'addUser':
        return <AddUser />
      case 'addExternalUser':
        return <AddExternalUser />
      case 'changePassword':
        return <ChangePassword />
      case 'chainPreview':
        return <ChainPreview />
      case 'projectDetails':
        return <ProjectDetails />
      case 'statusHistory':
        return <ProjectStatusHistory />
      case 'versionSelect':
        return <VersionSelect />
      case 'tubeWidth':
        return <TubeWidth />
      case 'projectSettings':
        return <ProjectSettingsModal />
      case 'noEstimator':
        return <EstimatorModal />
      case 'updateSignature':
        return <UpdateSignature />
      default:
        return null
    }
  }

  if (!modalKeys.includes(content)) return null

  return (
    <div id="modal">
      <div id="modal__content">{renderContent()}</div>
    </div>
  )
}

export default Modal
