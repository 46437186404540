import store from 'utils/../reduxStore'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import AuthenticationService from 'utils/api/authentication/AuthenticationService'
import { setLoading, setModalContent, openConfirmModal, openConfirmFormSubmit } from 'shared/redux/ScreenActions'
import { history } from 'App'

const loginSuccess = createAction('LOGIN_SUCCESS')

export const login = (credentials) => async (dispatch) => {
  const data = await AuthenticationService.login(credentials)
  if ('user' in data && 'token' in data) {
    localStorage.setItem('token', data.token)
    dispatch(loginSuccess(data.user))
  } else {
    dispatch(
      openConfirmModal({
        headerText: 'Error',
        bodyText: data.error,
        cancelButton: false,
        confirmButtonText: 'OK',
      })
    )
  }
}

export const codeExchange = (credentials) => async (dispatch) => {
  const data = await AuthenticationService.codeExchange(credentials)
  if ('user' in data && 'token' in data) {
    localStorage.setItem('token', data.token)
    dispatch(loginSuccess(data.user))
  } else {
    dispatch(
      openConfirmModal({
        headerText: 'Error',
        bodyText: data.error,
        cancelButton: false,
        confirmButtonText: 'OK',
      })
    )
  }
}

export const codeValidation = (request) => async (dispatch) => {
  await AuthenticationService.codeValidation(request)
}

export const changePassword = (oldPassword, newPassword, throwError) => async (dispatch) => {
  dispatch(setLoading({ loading: true }))
  const { userId } = store.getState().UserReducer
  const changedPassword = await AuthenticationService.changePassword(
    userId,
    oldPassword,
    newPassword
  )
  if (!changedPassword.error) {
    dispatch(setModalContent(null))
    dispatch(setLoading(false))
  } else {
    throwError({
      oldPassword: {
        errors: [
          new Error('Error updating password. Please check the existing password and try again.'),
        ],
      },
    })
    dispatch(setLoading(false))
    debugger
  }
}

export const checkProjectLock = (projectId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    return AuthenticationService.checkProjectLock(projectId)
      .then(({ islocked, username }) => {
        if (islocked) {
          dispatch(
            openConfirmModal({
              bodyText: `This project is locked, it will be released after 5 minutes of inactivity by person currently using it.\nThis project is currently being accessed by ${username}`,
              cancelButton: false,
              confirmButtonText: 'OK',
            })
          )
        }
        resolve(islocked)
      })
      .catch((e) => reject(e))
  })
}

export const uploadSignature = (file) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('SignatureImage', file)
    return AuthenticationService.createUpdateSignature(formData)
      .then(resolve)
      .catch((e) => reject(e))
  })
}

export const logout = createAction('LOGOUT')
