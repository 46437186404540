import * as Screen from 'screens'
import iconsMap from 'shared/components/Icon/iconsMap'

interface RouteConfigItem {
  icon?: keyof typeof iconsMap
  text: string
  component: React.ReactNode
  authority?: Array<string>
  inexact?: boolean
}

const configShared: Record<string, RouteConfigItem> = {
  '/callback/:callbackId': { text: 'Login Callback', component: Screen.Callback },
  '/logout': { text: 'Logout', component: Screen.Logout }
}

export const routeConfig: Record<string, RouteConfigItem> = {
  '/': { icon: 'home', text: 'Projects', component: Screen.Projects },
  '/project/:projectId/:versionId': { text: 'Project Summary', component: Screen.Project },
  '/project/:projectId/:versionId/validate': {
    text: 'Validate Price',
    component: Screen.Validation,
  },
  '/codevalidation': { text: 'Code validation', component: Screen.CodeValidation },
  '/admin': { text: 'Admin Dashboard', component: Screen.AdminDashboard, authority: ['Admin'] },
  '/admin/cost-import': { text: 'Cost Import', component: Screen.CostImport, authority: ['Admin'] },
  '/admin/static-parts': {
    text: 'Static Parts',
    component: Screen.StaticPartsScreen,
    authority: ['Admin'],
  },
  '/admin/active-projects': {
    text: 'Active Projects',
    component: Screen.ActiveProjects,
    authority: ['Admin'],
  },
  '/admin/user-management': {
    text: 'Internal User Management',
    component: Screen.UserManagement,
    authority: ['Admin'],
  },
  '/admin/price-configuration': {
    text: 'Price Configuration',
    component: Screen.PriceConfiguration,
    authority: ['Admin'],
  },
  '/admin/external-user-management': {
    text: 'External User Management',
    component: Screen.ExternalUserManagement,
    authority: ['Admin'],
  },
  '/admin/prefixes': {
    text: 'Project Settings',
    component: Screen.ProjectNumberPrefix,
    authority: ['Admin'],
  },
  ...configShared
}

export const routeConfigUnauthenticated: Record<string, RouteConfigItem> = {
  '/': { icon: 'home', text: 'Login', component: Screen.Login },
  ...configShared
}

export default routeConfig
