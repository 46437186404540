import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ActionMenu } from 'shared/components'
import Logo from 'shared/assets/logo.png'
import { setModalContent } from 'shared/redux/ScreenActions'
import './NavBar.scss'
import Breadcrumbs from './components/Breadcrumbs'
import getBreadcumbsConfig from './utils/getBreadcumbsConfig'
import { typedUseSelector } from 'utils/helpers'
import { ActionMenuOption } from 'shared/components/ActionMenu'

function NavBar() {
  const roles = typedUseSelector((state) => state.UserReducer.roles)
  const firstName = typedUseSelector((state) => state.UserReducer.firstName)
  const lastName = typedUseSelector((state) => state.UserReducer.lastName)
  const userName = typedUseSelector((state) => state.UserReducer.username)
  const logoutURL = typedUseSelector((state) => state.UserReducer.logoutUrl)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const options: Array<ActionMenuOption> = [
    {
      label: 'Log Out',
      handleSelect: () => {
        logoutURL && logoutURL.startsWith('http') 
        ? window.location.href = logoutURL 
        : history.push(logoutURL ?? '/logout')
      },
      icon: 'dotsH',
      className: 'navbar-action-menu__logout',
      type: 'warning',
    }
  ]

  if (!roles.includes('Admin')) {
    options.unshift(
      {
      label: 'Change Password',
      handleSelect: () => dispatch(setModalContent('changePassword')),
      icon: 'password',
      className: 'navbar-action-menu__password',
    })
  }

  if (roles.includes('AccountManager')) {
    options.unshift(
      {
      label: 'Update signature',
      handleSelect: () => dispatch(setModalContent('updateSignature')),
      icon: 'customers',
      className: 'navbar-action-menu__signature',
    })
  }

  if (roles.includes('Admin')) {
    options.unshift(
      {
        label: 'Admin Dashboard',
        handleSelect: () => {
          history.push('/admin')
        },
        icon: 'dashboard',
        className: 'navbar-action-menu__admin',
      },
      {
        label: 'Project Settings',
        handleSelect: () => history.push('/admin/prefixes'),
        icon: 'settings',
        className: 'navbar-action-menu__admin',
      },
      {
        label: 'Price Configuration',
        handleSelect: () => history.push('/admin/price-configuration'),
        icon: 'dollar',
        className: 'navbar-action-menu__admin',
      },
      {
        label: 'Cost Import',
        handleSelect: () => history.push('/admin/cost-import'),
        icon: 'dollar',
        className: 'navbar-action-menu__admin',
      },
      {
        label: 'Static Part Management',
        handleSelect: () => history.push('/admin/static-parts'),
        icon: 'dollar',
        className: 'navbar-action-menu__admin',
      },
      {
        label: 'Internal User Management',
        handleSelect: () => history.push('/admin/user-management'),
        icon: 'customers',
        className: 'navbar-action-menu__admin',
      },
      {
        label: 'External User Management',
        handleSelect: () => history.push('/admin/external-user-management'),
        icon: 'customers',
        className: 'navbar-action-menu__admin',
      }
    )
  }

  const title = `Connected as ${firstName} ${lastName} (${userName})`

  return (
    <div className="navbar">
      <header className="navbar__wrapper" style={{ padding: '0 80px', width: '100%' }}>
        <div className="navbar__left-container">
          <div className="navbar__logo">
            <img className="logo" src={Logo} alt="Span Tech Logo" title={title}/>
          </div>
          <Breadcrumbs crumbConfig={getBreadcumbsConfig(location.pathname)} />
        </div>
        <div>
          <ActionMenu
            menuTitle="Account"
            menuIcon="user"
            menuIconPosition="left"
            options={options}
          />
        </div>
      </header>
    </div>
  )
}

export default NavBar
