/* Type generated via scripts/generateTSSchemas.js */

/* The measuring unit. */
export enum MeasuringUnit {
  English = 'English',
  Metric = 'Metric',
}

export interface ITorqueArmBracketAndGussets {
  assemblypartno?: string;
  bracketpartno?: string;
  bracketwidth: number;
  bracketlength: number;
  gear?: string;
  gussetlength: number;
  gussetpartno?: string;
  gussetwidth: number;
  hidetoexternaluser: boolean;
  id: number;
  isdeleted: boolean;
  isnordbrand: boolean;
}

export enum MovingSideGuideSides {
  Left = 'Left',
  Right = 'Right',
  Both = 'Both',
}

export interface IChainPatternCenterElement {
  elementid: number;
  id: number;
  manualwidthmm?: number;
  position: number;
}

export interface IChainPattern {
  centerelements?: Array<IChainPatternCenterElement>;
  id: number;
  leftelementid?: number;
  patternindex: number;
  repeat?: number;
  rightelementid?: number;
}

export enum ElementUnit {
  EA = 'EA',
  FT = 'FT',
  PKG = 'PKG',
  IN = 'IN',
  QT = 'QT',
  EQ = 'EQ',
  ML = 'ML',
  SQE = 'SQE',
  SQF = 'SQF',
  R25 = 'R25',
  R50 = 'R50',
  RL = 'RL',
  SQI = 'SQI',
}

export interface IChainPart {
  description?: string;
  id: string;
  partnumber?: string;
  qty: number;
  source?: string;
  totallinklabor?: number;
  totalweight: number;
  unit: ElementUnit;
  unitlinklabor?: number;
  unitweight: number;
}

export interface IChain {
  alternaterow?: boolean;
  bluelinks?: boolean;
  centersupportrails?: number;
  chainparts?: Array<IChainPart>;
  chainpatterns?: Array<IChainPattern>;
  chainroddefinitionid?: number;
  chainserieid?: number;
  chainstyleid?: number;
  cleatheight?: number;
  cleatspacing?: number;
  coated?: boolean;
  costperfoot?: number;
  count302_1: number;
  count302_2: number;
  countcut1_1: number;
  countcut1_2: number;
  countcut2_1: number;
  countcut2_2: number;
  countcut3_1: number;
  countcut3_2: number;
  cut1_1: number;
  cut1_2: number;
  cut2_1: number;
  cut2_2: number;
  cut3_1: number;
  cut3_2: number;
  dashrollerandroptionornothing?: string;
  filllength_2: number;
  finalqtypiecesused: number;
  id: number;
  lengthfeet?: number;
  manhourbuildchain?: number;
  manhourmakechainrod?: number;
  movingsideguideheight?: number;
  movingsideguides: MovingSideGuideSides;
  numberofrods?: number;
  onefootlong?: boolean;
  reminderlengthwithmini_1: number;
  reminderlengthwithmini_2: number;
  returnchainsupportcorrected: number;
  returnchainsupportrails?: number;
  rodlengthlowerlimit: number;
  rodlengthupperlimit: number;
  sprocketscount?: number;
  standardchainstyle?: boolean;
  stdchainwidthid?: number;
  subtract: number;
  unit: MeasuringUnit;
  unitfromchainorconveyor: MeasuringUnit;
  weightperlengthenglish?: number;
  weightperlengthmetric: number;
  widthenglish?: number;
  widthmetric?: number;
  recommendedmaxangle?: number
  maxhelicalinclinedecline?: number
  minproductsizeenglish: number
  minproductsizemm: number
}

export enum ConveyorSectionType {
  Straight = 'Straight',
  VerticalCurve = 'VerticalCurve',
  HorizontalCurve = 'HorizontalCurve',
  Infeed = 'Infeed',
  Discharge = 'Discharge',
  MetalDetector = 'MetalDetector',
  IntermediateDrive = 'IntermediateDrive',
}

export enum TorqueValueState {
  OK = 'OK',
  Caution = 'Caution',
  Warning = 'Warning',
}

export interface IListElement {
  defaultsetid?: number;
  hidetoexternaluser: boolean;
  id: number;
  image?: string;
  isdefault: boolean;
  isdeleted: boolean;
  numericvalue: number;
  textvalue?: string;
  title?: string;
}

export interface IInfeed {
  feedtierod?: boolean;
  angle?: number;
  bottomforce: number;
  bottomforceaccum: number;
  bottomforceaccumstate: TorqueValueState;
  bottomforcestate: TorqueValueState;
  bottomtemp: number;
  bottomtempaccum: number;
  bottomtempaccumstate: TorqueValueState;
  bottomtempstate: TorqueValueState;
  feedbearingtypeid?: number;
  feeddriveandidlertypeid?: number;
  feeddriveorientation: IListElement;
  feeddriveorientationid?: number;
  feedidlertype: IListElement;
  feedidlertypeid?: number;
  feedtorquearmbracket?: boolean;
  feedtypeid?: number;
  hastransfer: boolean;
  id: number;
  incline?: number;
  length?: number;
  lengthforsectioninches: number;
  lengthinput116inchespart?: number;
  lengthinputfeetpart?: number;
  lengthinputinchespart?: number;
  lengthinputmm?: number;
  metaldetectorlengthinches: number;
  metaldetectortypeid?: number;
  radius?: number;
  sectionnumber?: number;
  standardbottomforce: number;
  standardchainpullspeed: number;
  standardtempattopturnexit: number;
  standardtemperature: number;
  standardtemperaturebottom: number;
  standardtopexitforce: number;
  standardtopexitspeed: number;
  standardtopforce: number;
  taildrivenbottomforce: number;
  taildrivenchainpullspeed: number;
  taildriventempatbottomturnexit: number;
  taildriventemperaturetop: number;
  taildriventopforce: number;
  tailshaftbottomforce: number;
  tailshaftchainpullspeed: number;
  tailshafttempatbottomturnexit: number;
  tailshafttemperaturetop: number;
  tailshafttopforce: number;
  topforce: number;
  topforceaccum: number;
  topforceaccumstate: TorqueValueState;
  topforcestate: TorqueValueState;
  toptemp: number;
  toptempaccum: number;
  toptempaccumstate: TorqueValueState;
  toptempstate: TorqueValueState;
  transferlabel?: string;
  type: ConveyorSectionType;
  warnings: string[];
  lengthinches: number;
  lengthmm: number;
}

export interface IDischarge {
  feedtierod?: boolean;
  angle?: number;
  bottomforce: number;
  bottomforceaccum: number;
  bottomforceaccumstate: TorqueValueState;
  bottomforcestate: TorqueValueState;
  bottomtemp: number;
  bottomtempaccum: number;
  bottomtempaccumstate: TorqueValueState;
  bottomtempstate: TorqueValueState;
  feedbearingtypeid?: number;
  feeddriveandidlertypeid?: number;
  feeddriveorientation: IListElement;
  feeddriveorientationid?: number;
  feedidlertype: IListElement;
  feedidlertypeid?: number;
  feedtorquearmbracket?: boolean;
  feedtypeid?: number;
  hastransfer: boolean;
  id: number;
  incline?: number;
  length?: number;
  lengthforsectioninches: number;
  lengthinput116inchespart?: number;
  lengthinputfeetpart?: number;
  lengthinputinchespart?: number;
  lengthinputmm?: number;
  metaldetectorlengthinches: number;
  metaldetectortypeid?: number;
  radius?: number;
  sectionnumber?: number;
  standardbottomforce: number;
  standardchainpullspeed: number;
  standardtempattopturnexit: number;
  standardtemperature: number;
  standardtemperaturebottom: number;
  standardtopexitforce: number;
  standardtopexitspeed: number;
  standardtopforce: number;
  taildrivenbottomforce: number;
  taildrivenchainpullspeed: number;
  taildriventempatbottomturnexit: number;
  taildriventemperaturetop: number;
  taildriventopforce: number;
  tailshaftbottomforce: number;
  tailshaftchainpullspeed: number;
  tailshafttempatbottomturnexit: number;
  tailshafttemperaturetop: number;
  tailshafttopforce: number;
  topforce: number;
  topforceaccum: number;
  topforceaccumstate: TorqueValueState;
  topforcestate: TorqueValueState;
  toptemp: number;
  toptempaccum: number;
  toptempaccumstate: TorqueValueState;
  toptempstate: TorqueValueState;
  transferlabel?: string;
  type: ConveyorSectionType;
  warnings:string[];
  lengthinches: number;
  lengthmm: number;
}

export interface ILegendFileField {
  bidirectional?: string;
  controllermounting?: string;
  controllerwired?: string;
  customernumber?: string;
  dischargeelevation?: string;
  earsmodified?: string;
  gearmotormounting?: string;
  gearmotornumber?: string;
  gearmotorratio?: string;
  gearmotorspec?: string;
  id: number;
  infeedelevation?: string;
  motorhz?: string;
  remotedrivensprk?: string;
  remotemount?: string;
  remotemounttype?: string;
  sprocketsmodified?: string;
  tocto2ndrail?: string;
  topkeeper?: string;
  torque?: string;
  transferoffdrive?: string;
  transferoffserialnumber?: string;
  transferondrive?: string;
  transferonserialnumber?: string;
  washdowndegree?: string;
}

export interface IConveyorSection {
  feedtierod?: boolean;
  angle?: number;
  bottomforce: number;
  bottomforceaccum: number;
  bottomforceaccumstate: TorqueValueState;
  bottomforcestate: TorqueValueState;
  bottomtemp: number;
  bottomtempaccum: number;
  bottomtempaccumstate: TorqueValueState;
  bottomtempstate: TorqueValueState;
  feedbearingtypeid?: number;
  feeddriveandidlertypeid?: number;
  feeddriveorientation: IListElement;
  feeddriveorientationid?: number;
  feedidlertype: IListElement;
  feedidlertypeid?: number;
  feedtorquearmbracket?: boolean;
  feedtypeid?: number;
  id: number;
  incline?: number;
  isaccessoriesoverridesopen: boolean;
  ismaterialoverridesopen: boolean;
  length?: number;
  lengthforsectioninches: number;
  lengthinput116inchespart?: number;
  lengthinputfeetpart?: number;
  lengthinputinchespart?: number;
  lengthinputmm?: number;
  metaldetectorlengthinches: number;
  metaldetectortypeid?: number;
  radius?: number;
  sectionnumber?: number;
  standardbottomforce: number;
  standardchainpullspeed: number;
  standardtempattopturnexit: number;
  standardtemperature: number;
  standardtemperaturebottom: number;
  standardtopexitforce: number;
  standardtopexitspeed: number;
  standardtopforce: number;
  taildrivenbottomforce: number;
  taildrivenchainpullspeed: number;
  taildriventempatbottomturnexit: number;
  taildriventemperaturetop: number;
  taildriventopforce: number;
  tailshaftbottomforce: number;
  tailshaftchainpullspeed: number;
  tailshafttempatbottomturnexit: number;
  tailshafttemperaturetop: number;
  tailshafttopforce: number;
  topforce: number;
  topforceaccum: number;
  topforceaccumstate: TorqueValueState;
  topforcestate: TorqueValueState;
  toptemp: number;
  toptempaccum: number;
  toptempaccumstate: TorqueValueState;
  toptempstate: TorqueValueState;
  type: ConveyorSectionType;
  warnings: string[];
  lengthforsectionmm: number;
}

export interface IPriceValidation {
  cost?: number;
  defaultmuf: number;
  details?: string;
  discountpercentage?: number;
  id: number;
  ismufdiscounteditable: boolean;
  labcost: number;
  labhrs: number;
  label?: string;
  listprice?: number;
  mandatory: boolean;
  materialcost: number;
  muf?: number;
  netprice?: number;
  order: number;
  pricevalidationoptionid?: number;
  pricevalidationoptionname?: string;
  quantity?: number;
  sharedmuf: boolean;
  totalcost: number;
}

export interface IProduct {
  accum?: string;
  accumlength?: string;
  accumulatedtorque: number;
  chainpull: number;
  chainpullaccumulated: number;
  conveyorid: number;
  description?: string;
  height?: number;
  heightlegend?: string;
  id: number;
  length?: number;
  lengthlegend?: string;
  load?: number;
  loadlegend?: string;
  material?: string;
  materialtypeid?: number;
  numberoflanes?: string;
  order?: number;
  rate?: number;
  ratelegend?: string;
  requiredtorque: number;
  spacing?: number;
  tempurature?: number;
  weight?: number;
  weightlegend?: string;
  width?: number;
  widthlegend?: string;
}

/* The gear motor class. Pretty self-explanatory what this represents ;) */
export interface IGearmotor {
  brakemotor: boolean;
  desiredunitid?: number;
  drivelabel?: string;
  gearmotorid?: number;
  gearmotorbrand?: string;
  gearmotorrpm?: number;
  hoursperdayofoperation: number;
  id: number;
  inputvoltage?: number;
  motoroutputvoltageid?: number;
  mountingtypeid?: number;
  movimotspeedctrlid?: number;
  remotemountangle?: number;
  remotemountdrivesprocketsteeth?: number;
  remotemountelevation?: number;
  remotemountflatinclinedeclineflagid?: number;
  speed133hz?: string;
  speed33hz?: string;
  speed60or50hz?: string;
  stopstartsperhour: number;
  vaconxseriesinverterid?: number;
  variablespeedoptionsid?: number;
  vfdselectionid?: number;
  zfanonmotor: boolean;
}

export interface IConveyor {
  accumulatedtorque: number;
  avgmuf: number;
  casesizename?: string;
  cautiontemp: number;
  cautiontorque: number;
  chain: IChain;
  chainpull: number;
  chainpullaccumulated: number;
  cleanroom?: boolean;
  contaminantspresent?: boolean;
  conveyorsections?: Array<IConveyorSection>;
  cost?: number;
  costuptodate: boolean;
  createdby?: string;
  createdbyname?: string;
  crumbcatchercalcold: number;
  discharge: IDischarge;
  dischargeelevation?: string;
  discountpercentage?: number;
  drivenotationassembly?: string;
  drivetypelabel?: string;
  failtemp: number;
  failtorque: number;
  frictionbottom?: number;
  frictionload?: number;
  frictiontop?: number;
  gear?: string;
  gearbox: ITorqueArmBracketAndGussets;
  gearboxid?: number;
  gearmotortypeid?: number;
  gearmotor: IGearmotor;
  gearmotorid: number;
  gearmotorspareparttitle?: string;
  id: number;
  infeed: IInfeed;
  infeedelevation?: string;
  legendfilefield: ILegendFileField;
  legendfilefieldid?: number;
  listprice?: number;
  materialid?: number;
  maxproductid?: number;
  mincrossbarspacingquantityproductids?: Array<number>;
  muf?: number;
  name?: string;
  netprice?: number;
  notes?: string;
  number?: string;
  order?: number;
  pricevalidations?: Array<IPriceValidation>;
  productdescription?: string;
  productheight?: number;
  productlength?: number;
  productload: number;
  productmaterialtypeid?: number;
  productrate?: number;
  productspacing?: number;
  productweight?: number;
  productwidth?: number;
  products?: Array<IProduct>;
  radiusminiflat: number;
  radiusminihelical: number;
  requiredtorque: number;
  seesheet2?: string;
  sourceofcontaminants?: string;
  speed?: number;
  state?: string;
  temperature?: number;
  unit: MeasuringUnit;
  lengthwithunit: string;
  conveyorbuilderimagesoutdated?: boolean;
}

/* The guide rails class represents conveyor options for guard rails. */
export interface IGuideRailsParameter {
  backingid?: number;
  bracketsid?: number;
  conveyor: IConveyor;
  conveyorid?: number;
  conveyorparametergroupid?: number;
  conveyorsection: IConveyorSection;
  conveyorsectionid?: number;
  distancebetweenrailsid?: number;
  distancebetweenrailsmanual: number;
  distancebetweenrailsmanualunit: MeasuringUnit;
  distancetocid?: number;
  distancetocmanual: number;
  distancetocmanualunit: MeasuringUnit;
  doublelevel?: boolean;
  ezguideid?: number;
  id: number;
  ispainted: boolean;
  parameteractive: boolean;
  railschoiceid?: number;
  removedforsection: boolean;
  rodid?: number;
  sideid?: number;
}

/* Represents a single side of a Guide Rail. */
export interface IGuideRailsSideResponse {
  backingid?: number;
  bracketsid?: number;
  conveyorupdated: IConveyor;
  distancebetweenrailsid: number;
  distancebetweenrailsmanual: number;
  distancetocid: number;
  distancetocmanual: number;
  doublelevel: boolean;
  ezguideid?: number;
  id: number;
  painted: boolean;
  parameteractive: boolean;
  railschoiceid?: number;
  removedforsection: boolean;
  rodid?: number;
  sectionoverride: boolean;
  sideid?: number;
}

/* The guide rails response. */
export interface IGuideRailsResponse {
  guiderailsonsection: boolean;
  left: IGuideRailsSideResponse;
  right: IGuideRailsSideResponse;
  sectionoverride: boolean;
}

/* The underguard response. */
export interface IUnderguardResponse {
  conveyorupdated: IConveyor;
  id: number;
  parameteractive: boolean;
  removedforsection: boolean;
  sectionoverride: boolean;
}

/* The drip tray response returns details of the drip tray on the current configuration. */
export interface IDripTrayResponse {
  conveyorupdated: IConveyor;
  id: number;
  materialid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
  sectionoverride: boolean;
}

/* The side wall by side parameter response. */
export interface ISideWallsSideResponse {
  conveyorupdated: IConveyor;
  customheight?: number;
  customheightunit: MeasuringUnit;
  distancetoc?: number;
  distancetocunit: MeasuringUnit;
  id: number;
  materialid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
  sectionoverride: boolean;
  sideid: number;
}

/* The sidewalls response. */
export interface ISideWallsResponse {
  left: ISideWallsSideResponse;
  right: ISideWallsSideResponse;
  sectionoverride: boolean;
  sidewallsonsection: boolean;
}

/* The fixed end stop response returns details of the fix end stop configuration for the current conveyor. */
export interface IFixedEndStopResponse {
  conveyorupdated: IConveyor;
  distancetoc?: number;
  distancetocunit: MeasuringUnit;
  id: number;
  mountinglocationid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
  sectionoverride: boolean;
}

/* The accessories response. */
export interface IAccessoriesDetailedResponse {
  driptray: IDripTrayResponse;
  fixedendstop: IFixedEndStopResponse;
  guiderails: IGuideRailsResponse;
  sidewalls: ISideWallsResponse;
  underguard: IUnderguardResponse;
}

/* The guide rails request is used for creating or updating a guide rail for a conveyor. */
export interface IGuideRailsRequest {
  backingid?: number;
  bracketsid?: number;
  distancebetweenrailsid?: number;
  distancebetweenrailsmanual: number;
  distancetocid?: number;
  distancetocmanual: number;
  doublelevel: boolean;
  ezguideid?: number;
  painted?: boolean;
  parameteractive: boolean;
  railschoiceid?: number;
  removedforsection: boolean;
  rodid?: number;
  sideid?: number;
}

/* The underguard request is for creating or updating values for an under guard. */
export interface IUnderguardRequest {
  parameteractive: boolean;
  removedforsection: boolean;
}

export interface IOperation {
  from?: string;
  op?: string;
  path?: string;
  value?: Record<string, unknown>;
}

/* The drip tray request represents the drip tray accessory for a conveyor. */
export interface IDripTrayRequest {
  materialid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
}

/* The side walls request for creating or updating conveyor sidewalls. */
export interface ISideWallsRequest {
  customheight?: number;
  customheightunit: MeasuringUnit;
  distancetoc?: number;
  distancetocunit: MeasuringUnit;
  materialid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
  sideid?: number;
}

/* The fixed end stop request for creating and updating this accessory. */
export interface IFixedEndStopRequest {
  distancetoc?: number;
  distancetocunit: MeasuringUnit;
  mountinglocationid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
}

/* The floor supports response. */
export interface IFloorSupportsResponse {
  conveyorupdated: IConveyor;
  doublestandoff: boolean;
  id: number;
  materialid: number;
  parameteractive: boolean;
  removedforsection: boolean;
  sectionoverride: boolean;
  supportmountingid: number;
}

/* The floor supports request for creating or modifying floor supports. */
export interface IFloorSupportsRequest {
  doublestandoff?: boolean;
  materialid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
  supportmountingid?: number;
}

/* The floor support details response. */
export interface IFloorSupportDetailsResponse {
  bracket: number;
  chainwidth: number;
  elevation: number;
  id: number;
  inclidedecline: number;
  lockcollars: number;
  quantity: number;
  styleid: number;
  thickwalls: boolean;
}

/* The floor support details request for creating or updating floor support details. */
export interface IFloorSupportDetailsRequest {
  bracket: number;
  chainwidth?: number;
  elevation?: number;
  inclidedecline?: number;
  lockcollars?: number;
  quantity?: number;
  styleid?: number;
  thickwalls: boolean;
}

/* The ceiling supports response. */
export interface ICeilingSupportsResponse {
  conveyorupdated: IConveyor;
  doublestandoff: boolean;
  id: number;
  materialid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
  sectionoverride: boolean;
}

/* The ceiling supports request for creating or updating ceiling supports. */
export interface ICeilingSupportsRequest {
  doublestandoff?: boolean;
  materialid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
}

/* Represents a ceiling support */
export interface ICeilingSupportDetailsResponse {
  bracketid: number;
  chainwidth: number;
  id: number;
  lockcollars: number;
  quantity: number;
  styleid: number;
  thickwalls: boolean;
}

/* The ceiling support details request for creating or modifying ceiling support details. */
export interface ICeilingSupportDetailsRequest {
  bracketid?: number;
  chainwidth?: number;
  lockcollars?: number;
  quantity?: number;
  styleid?: number;
  thickwalls: boolean;
}

/* The reset request. */
export type ResetRequest = Record<string, unknown>;

export type ResetResponse = Record<string, unknown>;

/* The side wall parameter defines options for side walls that can be applied to a conveyor. */
export interface ISideWallsParameter {
  conveyor: IConveyor;
  conveyorid?: number;
  conveyorparametergroupid?: number;
  conveyorsection: IConveyorSection;
  conveyorsectionid?: number;
  customheight?: number;
  customheightunit: MeasuringUnit;
  distancetoc?: number;
  distancetocunit: MeasuringUnit;
  id: number;
  materialid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
  sideid?: number;
}

export enum PeriodEnum {
  Month = 'Month',
  Week = 'Week',
  Day = 'Day',
}

export enum FilterTypes {
  All = 'All',
  Employee = 'Employee',
  Customer = 'Customer',
  Status = 'Status',
}

export enum Departments {
  AccountManager = 'AccountManager',
  Designer = 'Designer',
  Estimator = 'Estimator',
  Client = 'Client',
  Manager = 'Manager',
  All = 'All',
}

export enum NonStandardType {
  RowPattern = 'RowPattern',
  NonStandardWidth = 'NonStandardWidth',
  None = 'None',
}

export interface IWidth {
  id: number;
  valueinches: number;
  valuemm: number;
}

export enum ElementPosition {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export enum ElementMaterial {
  Santoprene = 'Santoprene',
  Polyurethane = 'Polyurethane',
}

export interface IChainElement {
  baseunitpriceblue: number;
  baseunitpricewhite: number;
  bluepartlabel?: string;
  bluepartnumber?: string;
  bluepartnumberguid: string;
  bluepartweight: number;
  category?: string;
  id: number;
  image?: string;
  imagefilename?: string;
  istubeorspacer: boolean;
  linklabor: number;
  material: ElementMaterial;
  position: ElementPosition;
  source?: string;
  sourceblue?: string;
  subcategory?: string;
  unit: ElementUnit;
  unitblue: ElementUnit;
  updateddate: string;
  updateddateblue: string;
  whitepartlabel?: string;
  whitepartnumber?: string;
  whitepartnumberguid: string;
  whitepartweight: number;
  widthmm: number;
}

export enum ChainRodType {
  Standard = 'Standard',
  Spiral = 'Spiral',
  Simple = 'Simple',
}

export interface IChainRodDefinition {
  chainrodtype: ChainRodType;
  coateddescription?: string;
  coatedname?: string;
  coatedpartidpattern?: string;
  coatedpartidpatternjtrax?: string;
  id: number;
  isspiral: boolean;
  metaltype?: string;
  position: number;
  uncoateddescription?: string;
  uncoatedname?: string;
  uncoatedpartidpattern?: string;
  uncoatedpartidpatternjtrax?: string;
  weightcoefficient: number;
}

export interface IChainSerie {
  additonalwidths?: Array<IWidth>;
  bluelinksavailable: boolean;
  centerelementdefaultcategory?: string;
  centerelements?: Array<IChainElement>;
  coatedchainrods?: Array<IChainRodDefinition>;
  elementlength: number;
  horizontalsectionallowed: boolean;
  id: number;
  leftelementdefaultcategory?: string;
  leftelements?: Array<IChainElement>;
  name?: string;
  nonstandardtype: NonStandardType;
  nonstandardwidths?: Array<IWidth>;
  patterncountmax: number;
  position: number;
  rightelementdefaultcategory?: string;
  rightelements?: Array<IChainElement>;
  rowperpatternmax: number;
  straightsectionallowed: boolean;
  suggestedwidths?: Array<IWidth>;
  uncoatedchainrods?: Array<IChainRodDefinition>;
  uniquechainroddefinitionid?: number;
  verticalsectionallowed: boolean;
  hideforlaproject: boolean;
}

export interface IProductResponse {
  accum?: string;
  accumlength?: string;
  accumulatedtorque: number;
  chainpull: number;
  chainpullaccumulated: number;
  conveyorid: number;
  description?: string;
  height?: number;
  heightlegend?: string;
  id: number;
  length?: number;
  lengthlegend?: string;
  load?: number;
  loadlegend?: string;
  material?: string;
  materialtypeid?: number;
  numberoflanes?: string;
  order?: number;
  rate?: number;
  ratelegend?: string;
  requiredtorque: number;
  spacing?: number;
  tempurature?: number;
  weight?: number;
  weightlegend?: string;
  width?: number;
  widthlegend?: string;
}

export interface IConveyorResponse {
  accumulatedtorque: number;
  avgmuf: number;
  casesizename?: string;
  cautiontemp: number;
  cautiontorque: number;
  chain: IChain;
  chainpull: number;
  chainpullaccumulated: number;
  cleanroom?: boolean;
  contaminantspresent?: boolean;
  conveyorsections?: Array<IConveyorSection>;
  cost?: number;
  costuptodate: boolean;
  createdby?: string;
  createdbyname?: string;
  crumbcatchercalcold: number;
  discharge: IDischarge;
  dischargeelevation?: string;
  discountpercentage?: number;
  drivenotationassembly?: string;
  drivetypelabel?: string;
  failtemp: number;
  failtorque: number;
  frictionbottom?: number;
  frictionload?: number;
  frictiontop?: number;
  gear?: string;
  gearbox: ITorqueArmBracketAndGussets;
  gearboxid?: number;
  gearmotortypeid?: number;
  gearmotor: IGearmotor;
  gearmotorid: number;
  gearmotorspareparttitle?: string;
  id: number;
  infeed: IInfeed;
  infeedelevation?: string;
  legendfilefield: ILegendFileField;
  legendfilefieldid?: number;
  listprice?: number;
  materialid?: number;
  maxproductid?: number;
  mincrossbarspacingquantityproductids?: Array<number>;
  muf?: number;
  name?: string;
  netprice?: number;
  notes?: string;
  number?: string;
  order?: number;
  pricevalidations?: Array<IPriceValidation>;
  productdescription?: string;
  productheight?: number;
  productlength?: number;
  productload: number;
  productmaterialtypeid?: number;
  productrate?: number;
  productspacing?: number;
  productweight?: number;
  productwidth?: number;
  products?: Array<IProductResponse>;
  radiusminiflat: number;
  radiusminihelical: number;
  requiredtorque: number;
  seesheet2?: string;
  sourceofcontaminants?: string;
  speed?: number;
  state?: string;
  temperature?: number;
  unit: MeasuringUnit;
  lengthwithunit: string;
}

export interface IInfeedTransfer {
  bearingtypeid?: number;
  bottomguardtypeid?: number;
  driveboxlocationid?: number;
  drivemethodid?: number;
  fingerontransfer?: boolean;
  highfrictionrods?: boolean;
  id: number;
  nosebarid?: number;
  numberofrods?: number;
  numberoftransfers?: number;
  plainorraisedid?: number;
  rollermaterialid?: number;
  transferhandid?: number;
  transfermaterialid?: number;
  transfertypeid?: number;
}

export interface IDischargeTransfer {
  bearingtypeid?: number;
  bottomguardtypeid?: number;
  driveboxlocationid?: number;
  drivemethodid?: number;
  fingerontransfer?: boolean;
  highfrictionrods?: boolean;
  id: number;
  nosebarid?: number;
  numberofrods?: number;
  numberoftransfers?: number;
  plainorraisedid?: number;
  rollermaterialid?: number;
  transferhandid?: number;
  transfermaterialid?: number;
  transfertypeid?: number;
}

/* The price validation request. */
export interface IPriceValidationRequest {
  cost?: number;
  defaultmuf: number;
  details?: string;
  discountpercentage?: number;
  id: number;
  ismufdiscounteditable: boolean;
  labcost: number;
  labhrs: number;
  label?: string;
  listprice?: number;
  mandatory: boolean;
  materialcost: number;
  muf?: number;
  netprice?: number;
  order: number;
  pricevalidationoptionid?: number;
  pricevalidationoptionname?: string;
  quantity?: number;
  sharedmuf: boolean;
  totalcost: number;
}

export interface IPart {
  code?: string;
  conveyorid: number;
  cost: number;
  description?: string;
  gauge?: string;
  hidecost: boolean;
  id: string;
  index: number;
  ismaterial: boolean;
  lacost: number;
  notes?: string;
  partnumber?: string;
  partselectionsettingid?: number;
  phantom?: string;
  productcode?: string;
  quantity: number;
  recommendedspare?: string;
  relatedtitlelineindex?: number;
  source?: string;
  unit?: string;
}

export interface ICrossBarLocation {
  anglefrom0?: string;
  anglefromcenterxbar?: string;
}

export interface ICrossBarChordSideFrame {
  chord1: number;
  chord2: number;
}

export interface ICrossBarChord {
  insidesideframe: ICrossBarChordSideFrame;
  outsidesideframe: ICrossBarChordSideFrame;
}

export interface IHorizontalBendPDFResponse {
  bendangle?: string;
  bendindex: number;
  centerlineradius?: string;
  chainwidth?: string;
  conveyorname?: string;
  crossbarchords?: Array<ICrossBarChord>;
  crossbarlocations?: Array<ICrossBarLocation>;
  endchordinside: number;
  endchordoutside: number;
  horizontalbendpartnumber?: string;
  inclinedecline?: string;
  insidecurveframeaverage?: string;
  insidecurvehelicalframe?: string;
  insidecurvestockcutlength: number;
  ishelicalbend: boolean;
  lipdimentionlower?: string;
  lipdimentionupper?: string;
  outsidecurveframeaverage?: string;
  outsidecurvehelicalframe?: string;
  outsidecurvestockcutlength: number;
  parts?: Array<IPart>;
  projectname?: string;
  quantity: number;
  rollradiusinside?: string;
  rollradiusoutside?: string;
}

export interface ISytelineResponse {
  chainrodpartnumber?: string;
  currentcost: number;
  description?: string;
  dutycost: number;
  excluded: boolean;
  freightcost: number;
  id?: number;
  partnumber?: string;
  partnumberguid: string;
  source?: string;
  sytelinecost: number;
  totalcost: number;
  unit?: string;
  updatedcost: number;
  updateddate: string;
}

export interface IPartsUpdateResponse {
  description?: string;
  isupdated: boolean;
  partnumberguid: string;
  partnumber?: string;
  source?: string;
  unit?: string;
  updateddate: string;
}

export interface IMiscellanousPartSelection {
  bandsawcost: number;
  bandsawsetup: number;
  deburrtime: number;
  gacutspeed161412: number;
  id: number;
  lasercutspeed7ga: number;
  pcsqinchedperhour: number;
  powdercoat: number;
  timeperbend: number;
  weldtime: number;
}

export enum PriceValidationType {
  Straight = 'Straight',
  Horizontal = 'Horizontal',
  Helical = 'Helical',
  EngineeringCost = 'EngineeringCost',
  EngineeringMUF = 'EngineeringMUF',
  MicrospanTransfer = 'MicrospanTransfer',
  Powertrain = 'Powertrain',
  LAMuf = 'LAMuf',
  LaborCostSettings = 'LaborCostSettings',
  AssemblyCost = 'AssemblyCost',
}

export interface IMUFUpdateResponse {
  muftype: PriceValidationType;
  updateddate: string;
  value: number;
}

export interface IVersionNote {
  createddatetime: string;
  id: number;
  note?: string;
  updateddatetime: string;
  userfullname?: string;
  userid?: string;
  versionid: number;
}

export interface IStatus {
  department: Departments;
  id: number;
  isexpiredstatus: boolean;
  lockcontent: boolean;
  name?: string;
  order: number;
  private: boolean;
  sentence?: string;
}

export interface IVersionStatusHistory {
  datetime: string;
  id: number;
  notes?: string;
  status: IStatus;
  statusname?: string;
  statussentence?: string;
  user?: string;
  username?: string;
}

export interface IActivationPeriod {
  id: number;
  inactivationdate: string;
  manualdeactivation: boolean;
  startdate: string;
  versionid?: number;
}

export interface IVersion {
  activationperiods?: Array<IActivationPeriod>;
  avgmuf: number;
  basedonversionid?: number;
  conveyorscount: number;
  cost: number;
  createdby?: string;
  createdbyname?: string;
  createddatetime: string;
  currentstatusname?: string;
  discountpercentage: number;
  hasnewgearmotor: boolean;
  hidden: boolean;
  id: number;
  index: number;
  isactive: boolean;
  islocked: boolean;
  ismaster: boolean;
  lastcalculationdatetime: string;
  lastmodifiedby?: string;
  lastmodifiedbyname?: string;
  lastmodifieddatetime: string;
  legendquotefilename?: string;
  listprice: number;
  name?: string;
  netprice: number;
  notes?: string;
  pricevalidations?: Array<IPriceValidation>;
  statushistory?: Array<IVersionStatusHistory>;
  userid?: string;
  validated: boolean;
  validateddatetime?: string;
  versionnotes?: Array<IVersionNote>;
}

export interface ILaborCostSetting {
  description?: string;
  id: number;
  laborperhr: number;
  machineperhr: number;
  setupperhr: number;
}

export interface IGeneralCost {
  assemblycost: number;
  creationdate: string;
  dutypercentage: number;
  engineeringcost: number;
  freightpercentage: number;
  id: number;
  iscurrentversion: boolean;
  laborcostsettings?: Array<ILaborCostSetting>;
  lamuf: number;
  userid?: string;
  versionnumber: number;
  versions?: Array<IVersion>;
}

export interface IPriceConfigrationResponse {
  generalcost: IGeneralCost;
  mufupdates?: Array<IMUFUpdateResponse>;
  updateddate: string;
}

export interface ICustomer {
  city?: string;
  defaultdiscount: number;
  faxnumber?: string;
  id: number;
  name?: string;
  number?: string;
  phonenumber?: string;
  state?: string;
  streetaddress1?: string;
  streetaddress2?: string;
  zipcode?: string;
}

export interface IContact {
  emailaddress?: string;
  firstname?: string;
  id: number;
  lastname?: string;
  phonenumber?: string;
  sytelineid: number;
}

export interface IGearMotorSpecResponse {
  description?: string;
  gearmotorid: number;
}

export interface IGearmotorSpecsAggregateResponse {
  desiredunitid: number;
  gearmotors?: Array<IGearMotorSpecResponse>;
  torquerange: number;
}

export interface IGearmotorResponse {
  brakemotor: boolean;
  desiredunitid: number;
  gearmotorid: number;
  gearmotorbrand?: string;
  gearmotorrpm?: number;
  hoursperdayofoperation: number;
  id: number;
  inputvoltage: number;
  motoroutputvoltageid: number;
  mountingtypeid: number;
  movimotspeedctrlid: number;
  remotemountangle?: number;
  remotemountdrivesprocketsteeth?: number;
  remotemountelevation?: number;
  remotemountflatinclinedeclineflagid?: number;
  speed133hz?: string;
  speed33hz?: string;
  speed60or50hz?: string;
  stopstartsperhour: number;
  vaconxseriesinverterid: number;
  variablespeedoptionsid: number;
  vfdselectionid?: number;
  zfanonmotor: boolean;
}

/* The gear motor request for creating or updating gear motors. */
export interface IGearmotorRequest {
  brakemotor?: boolean;
  desiredunitid?: number;
  gearmotorid?: number;
  gearmotorbrand?: string;
  gearmotorrpm?: number;
  hoursperdayofoperation?: number;
  inputvoltage?: number;
  motoroutputvoltageid?: number;
  mountingtypeid?: number;
  movimotspeedctrlid?: number;
  remotemountangle?: number;
  remotemountdrivesprocketsteeth?: number;
  remotemountelevation?: number;
  remotemountflatinclinedeclineflagid?: number;
  stopstartsperhour?: number;
  vaconxseriesinverterid?: number;
  variablespeedoptionsid?: number;
  vfdselectionid?: number;
  zfanonmotor?: boolean;
}

export interface IVFDSelection {
  hp: number;
  id: number;
  inputvoltage: number;
  isvacon: boolean;
  iswashdown: boolean;
  name?: string;
}

/* Structure for the log request from Front UI */
export interface IWebLog {
  location?: string;
  stack?: string;
}

/* The washdown request for creating or updating a washdown on a conveyor configuration. */
export interface IWashdownRequest {
  parameteractive: boolean;
  removedforsection: boolean;
}

/* Represents a single instance of an API wash down response.
This can be associated with conveyors and conveyor sections. */
export interface IWashdownResponse {
  conveyorupdated: IConveyor;
  id: number;
  parameteractive: boolean;
  removedforsection: boolean;
  sectionoverride: boolean;
}

/* The side frame request for creating or updating side frames on a conveyor. */
export interface ISideFrameRequest {
  cleanouttypeid?: number;
  manualcolor?: string;
  materialid?: number;
  nonestandardcolorid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
  standardcolorid?: number;
}

/* The side frame response. */
export interface ISideFrameResponse {
  cleanouttypeid?: number;
  conveyorupdated: IConveyor;
  id: number;
  manualcolor?: string;
  materialid?: number;
  nonestandardcolorid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
  sectionoverride: boolean;
  standardcolorid?: number;
}

/* The wearstrip request is for creating or updating wearstrips on a conveyor. */
export interface IWearstripRequest {
  lubricated?: boolean;
  materialid?: number;
  parameteractive: boolean;
  removedforsection: boolean;
}

/* The represents a single api response for a wear strip. */
export interface IWearstripResponse {
  conveyorupdated: IConveyor;
  id: number;
  lubricated: boolean;
  materialid: number;
  parameteractive: boolean;
  removedforsection: boolean;
  sectionoverride: boolean;
}

/* The materials response. */
export interface IMaterialsResponse {
  conveyorupdated: IConveyor;
  id: number;
  parameteractive: boolean;
  removedforsection: boolean;
  sectionoverride: boolean;
}

/* The materials detailed response provides an aggregate view of all materials associated with a conveyor or conveyor section. */
export interface IMaterialsDetailedResponse {
  sideframes: ISideFrameResponse;
  washdown: IWashdownResponse;
  wearstrips: IWearstripResponse;
}

export enum PriceType {
  US = 'US',
  Euro = 'Euro',
}

export interface IProject {
  accountmanagerid?: string;
  accountmanagername?: string;
  contact: IContact;
  contactid?: number;
  createdby?: string;
  createdbyname?: string;
  customer: ICustomer;
  customerid?: number;
  customernumber?: string;
  designerid?: string;
  designername?: string;
  estimatorid?: string;
  estimatorname?: string;
  id: number;
  isactive: boolean;
  isexternalproject: boolean;
  externalprojecttype?: string;
  issyncedwithsyteline: boolean;
  masterversion: IVersion;
  modifiedby?: string;
  modifiedbyname?: string;
  modifieddatetime: string;
  name?: string;
  number?: string;
  price: PriceType;
  processflow?: string;
  threedsolidfilerequired: boolean;
  versionscount: number;
  hasconveyorbuilderimage: boolean;
}

export interface IAction {
  id: number;
  name?: string;
}

export interface IVersionResponse {
  activationperiods?: Array<IActivationPeriod>;
  authorizedactions?: Array<IAction>;
  avgmuf: number;
  basedonversionid?: number;
  conveyorscount: number;
  cost: number;
  createdby?: string;
  createdbyname?: string;
  createddatetime: string;
  currentstatusname?: string;
  discountpercentage: number;
  editablebyuser: boolean;
  hasnewgearmotor: boolean;
  hidden: boolean;
  id: number;
  index: number;
  isactive: boolean;
  islocked: boolean;
  ismaster: boolean;
  lastcalculationdatetime: string;
  lastmodifiedby?: string;
  lastmodifiedbyname?: string;
  lastmodifieddatetime: string;
  legendquotefilename?: string;
  listprice: number;
  name?: string;
  netprice: number;
  notes?: string;
  pricevalidations?: Array<IPriceValidation>;
  statushistory?: Array<IVersionStatusHistory>;
  validated: boolean;
  validateddatetime?: string;
  versionnotes?: Array<IVersionNote>;
}

export interface IQueueResponse {
  project: IProject;
  version: IVersionResponse;
}

/* The project summary model used for the sales force integration to provide high-level details on a single project. */
export interface IProjectSummary {
  assignedaccountmanagerfullname?: string;
  assigneddesignerfullname?: string;
  assignedestimatorfullname?: string;
  city?: string;
  contactemailaddress?: string;
  contactfullname?: string;
  contactphonenumber?: string;
  createdat: string;
  creator?: string;
  customername?: string;
  customernumber?: string;
  id: number;
  projectname?: string;
  projectnumber?: string;
  state?: string;
  streetaddress1?: string;
  streetaddress2?: string;
  updatedat: string;
  zipcode?: string;
}

/* The version status summary provides a basic summary of a status entry for a single project version. */
export interface IVersionStatusSummary {
  name?: string;
  notes?: string;
  statussetat: string;
  versionid: number;
}

/* The version summary model represent a single high-level representation of a version associated with a project. */
export interface IVersionSummary {
  createdat: string;
  currentstatus: IVersionStatusSummary;
  expirationdate?: string;
  hasgearmotor: boolean;
  id: number;
  ismaster: boolean;
  lastmodifiedat?: string;
  lastmodifiedbyfullname?: string;
  notes?: string;
  projectaveragemuf: number;
  projectcost: number;
  projectdiscount: number;
  projectid: number;
  projectlistprice: number;
  projectprice: number;
  totalconveyorcount: number;
  updatedat: string;
  versionnumber: number;
}

export interface IGeneralSettingsRequest {
  customerserviceprefix?: string;
  externaluserprefix?: string;
  deactivationafterdays?: number;
  lauserprefix?: string;
  projectprefix?: string;
  spareprojectprefix?: string;
}

export interface IDutyAndFreightResponse {
  dutypercentage: number;
  freightpercentage: number;
}

/* Request structure to authentication */
export interface ILoginModel {
  password?: string;
  username?: string;
  captchaValue?: string;
}

/* Request structure to authentication by code exchange */
export interface ICodeExchangeLoginModel {
  grant_type: string;
  code?: string;
  callbackId?: string;
}

/* Request structure for code validation */
export interface ICodeValidationRequest {
  user_code: string;
}

/* structure for user profile operation */
export interface IUserDetail {
  accessfailedcount: number;
  concurrencystamp?: string;
  contact: IContact;
  contactid?: number;
  customer: ICustomer;
  customerid?: number;
  email?: string;
  emailconfirmed: boolean;
  firstname?: string;
  id?: string;
  regularproject: boolean;
  laproject:boolean;
  issparepartuser: boolean;
  lastname?: string;
  lockoutenabled: boolean;
  lockoutend?: string;
  normalizedemail?: string;
  normalizedusername?: string;
  password?: string;
  passwordhash?: string;
  permissions?: Array<string>;
  phonenumber?: string;
  phonenumberconfirmed: boolean;
  roles?: Array<string>;
  securitystamp?: string;
  signaturefilename?: string;
  twofactorenabled: boolean;
  username?: string;
}

/* Request structure for a password change */
export interface IChangePassWordRequest {
  id: number;
  newpassword?: string;
  oldpassword?: string;
}

export interface IConveyorOrderRequest {
  conveyorid: number;
  order: number;
}

export interface IActionDetail {
  notes?: string;
}

export interface IWidthsResult {
  nonstandard?: Array<IWidth>;
  standard?: Array<IWidth>;
}
